var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.checkActiveSms === true
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogVisibleSms,
                width: "1200px",
                "before-close": _vm.closeDialog,
                "close-on-click-modal": false,
                top: "2vh",
                title: "Thông tin gửi qua SMS nhân sự",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisibleSms = $event
                },
              },
            },
            [
              _c("div", { staticClass: "row-heard" }, [
                _c("div", { staticClass: "row-heard__item" }, [
                  _c("p", [
                    _vm._v("\n          Số người nhận:\n          "),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(this.numberReciver)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row-heard__item" }, [
                  _c("p", [
                    _vm._v(
                      "\n          Số người chưa được kích hoạt:\n          "
                    ),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(this.numberNoActive)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row-heard__item" }, [
                  _c("p", [
                    _vm._v("\n          Tổng số bản tin:\n          "),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(this.totalpackage)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row-heard__item" }, [
                  _c("p", [
                    _vm._v("\n          Số tin:"),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(this.numberpackage)),
                    ]),
                  ]),
                ]),
                !this.smsMore
                  ? _c("div", { staticClass: "row-heard__item" }, [
                      _c("p", [
                        _vm._v("\n          Sms hạn mức:\n          "),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(this.smsRemain)),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "el-form",
                {
                  ref: "createEmployeeNotifySms",
                  attrs: {
                    "label-width": "140px",
                    model: _vm.createEmployeeNotifySms,
                    "label-position": "left",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 40 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "font-weight": "bold" },
                                attrs: { label: "Tiêu đề: " },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.createEmployeeNotifySms.sendTitle
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "form-content" }, [
                              _c(
                                "div",
                                { staticClass: "form-content__box" },
                                [
                                  _c("h4", [_vm._v("Nội dung:")]),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-left": "0" },
                                      attrs: { prop: "sendContent" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          rows: 10,
                                          type: "textarea",
                                          placeholder:
                                            "Nhập nội dung tiếng việt có dấu ...",
                                          "on-change": _vm.converStrSms(
                                            _vm.createEmployeeNotifySms
                                              .sendContent
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.removeAccents(
                                              _vm.createEmployeeNotifySms
                                                .sendContent
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.createEmployeeNotifySms
                                              .sendContent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.createEmployeeNotifySms,
                                              "sendContent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "createEmployeeNotifySms.sendContent",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-content__box" },
                                [
                                  _c("h4", [_vm._v("Hiển thị:")]),
                                  _c("el-input", {
                                    attrs: {
                                      disabled: "",
                                      rows: 10,
                                      type: "textarea",
                                    },
                                    model: {
                                      value: _vm.contentSmsCv,
                                      callback: function ($$v) {
                                        _vm.contentSmsCv = $$v
                                      },
                                      expression: "contentSmsCv",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "row-checkbox" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "margin-right": "25px" },
                                    attrs: { label: false },
                                    model: {
                                      value: _vm.createEmployeeNotifySms.timer,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.createEmployeeNotifySms,
                                          "timer",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "createEmployeeNotifySms.timer",
                                    },
                                  },
                                  [_vm._v("Gửi ngay")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "margin-right": "25px" },
                                    attrs: { label: true },
                                    model: {
                                      value: _vm.createEmployeeNotifySms.timer,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.createEmployeeNotifySms,
                                          "timer",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "createEmployeeNotifySms.timer",
                                    },
                                  },
                                  [_vm._v("Hẹn giờ")]
                                ),
                                _vm.createEmployeeNotifySms.timer
                                  ? _c("el-date-picker", {
                                      attrs: {
                                        clearable: false,
                                        "value-format": "yyyy-MM-dd - HH:mm",
                                        type: "datetime",
                                        placeholder: "Chọn thời gian",
                                      },
                                      model: {
                                        value:
                                          _vm.createEmployeeNotifySms.dateTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.createEmployeeNotifySms,
                                            "dateTime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "createEmployeeNotifySms.dateTime",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-infor-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialogByButton(
                            "createEmployeeNotifySms"
                          )
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v("Đóng")]),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButton,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitFromNew()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Gửi")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }